export default class BatteryMeasurement {

    id = null
    measurements = {}
    //measured_under_load = false
    acidLevels = {}
    load_current = 0
    load_duration = 0
    group_id = null
    battery_temp =null
    battery_sum_voltage = null
    cellErrors = null
    measurement_round = 0
    planned_measurement_number = null
    measurement_type = null
    acidMeasurement = false
    notes = null

    constructor(cellsCount, measurements = null, id = null, groupId = null) {
        this.id = id
        if(measurements)
            this['group_id'] = measurements['group_id']
        else
            this['group_id'] = groupId || 0
        if (id === null)
            delete this.id
        this.cellsCount = cellsCount
        if (measurements) {
            this.id = measurements.id
            this.cellErrors = measurements.cellErrors
            //this['measured_under_load'] = measurements['measured_under_load']
            if(measurements.measurementValues){
                this.measurements = {}
                measurements.measurementValues.forEach((element,index) => {
                    this.measurements[index + 1] = element
                })
            } else {
                this.measurements = measurements.measurements || measurements.keyedMeasurementValues
            }
            this['load_current'] = parseInt(measurements['load_current'])
            this['load_duration'] = parseInt(measurements['load_duration'])
            this['battery_temp'] = parseFloat(measurements['battery_temp'])
            this['battery_temp_measured_at_cell'] = parseInt(measurements['battery_temp_measured_at_cell'])
            this['measurement_round'] = measurements['measurement_round']
            this['planned_measurement_number'] = measurements['planned_measurement_number']
            this['measurement_type'] = measurements['measurement_type']
            this['battery_sum_voltage'] = measurements['battery_sum_voltage']
            this['notes'] = measurements['notes']
            if (measurements.acidLevelsByCell || measurements.acidLevels) {
                Object.assign(this.acidLevels, measurements.acidLevelsByCell || measurements.acidLevels)
                delete this.measurements.acidLevelsByCell
            } else {
              this.acidLevels = {}
              for (let i = 0; i < measurements.measurementValues.length; i++) {
                this.acidLevels[i+1] = measurements.measurementValues[i].acid_density;

              }
              //this.acidLevels = measurements.measurementValues.map(prop=>prop.acid_density) Do not use!!! Old code
            }
        } else {
            /*this['measured_under_load'] = isUnderLoad
            if (this['measured_under_load']) {
                this['load_duration'] = loadDuration
                this['load_current'] = amps
            }*/
            this.createMeasurements()
        }
        this.checkVoltages()
        this.addAcidLevels()

    }
    isSumValueValid(){
      const voltagesSum = Object.values(this.measurements).reduce((partialSum, a) => partialSum + parseFloat(a['cell_voltage']), 0);
      return !(this['battery_sum_voltage'] < (voltagesSum - 0.025)) || (this['battery_sum_voltage'] > (voltagesSum + 0.025))
    }
    getCellVoltagesSum(){
      return Object.values(this.measurements).reduce((partialSum, a) => partialSum + parseFloat(a['cell_voltage']), 0);
    }
    isMeasurementDataValid(){
      if(!this.isSumValueValid()){
        console.log('bad sum')
        return false
      }
      let isValid = true
      Object.values(this.measurements).forEach(cell=>{
        if(!cell.ok && (this.acidLevels[cell.cell] === null || this.acidLevels[cell.cell]==='')){
          console.log('bad cell')
          isValid = false
        }
      })
      return isValid
    }

    checkVoltages(){
        const sortedMeasurements = Object.values(this.measurements).sort((a, b) => parseFloat(a['cell_voltage']) > parseFloat(b['cell_voltage']) ? -1 : 0)
        const tmp = sortedMeasurements[parseInt(sortedMeasurements.length/2)]
        sortedMeasurements[parseInt(sortedMeasurements.length/2)] = sortedMeasurements[0]
        sortedMeasurements[0] = tmp
        sortedMeasurements.forEach((cell) => {cell.ok = false})
        const groups = []
        sortedMeasurements.forEach((cell) => {
          let found = false
          if(!cell['cell_voltage'])
            cell['cell_voltage'] = 0
          groups.forEach( (group) =>{
            if(!found && parseFloat(cell['cell_voltage']) <= (parseFloat(group[0]['cell_voltage']) +0.025) && parseFloat(cell['cell_voltage']) >= (parseFloat(group[0]['cell_voltage']) -0.025)){
              group.push(cell)
              found = true
            }
          })
          if (!found)
              groups.push([cell])
        })
        let max = 0
        let maxgroup = null
        groups.forEach( (group) => {
          if (group.length > max && group && group[0]['cell_voltage'] > 0){
            maxgroup = group
            max = group.length
          }
        })
        if (maxgroup){
          maxgroup.forEach((cell) => {
            cell.ok = true
          })
        }
        sortedMeasurements.forEach((cell) => {
            if(cell['cell_voltage'] === 0


            ){
              cell['cell_voltage'] = ''
            }
            if((this.acidLevels && this.acidLevels[cell.cell])){
                cell.acidSelected = true
                this.acidMeasurement = true
            } else if(!cell.ok) {
                cell.acidSelected = true
            } else {
                cell.acidSelected = false
            }
        })
    }

    addAcidLevels = () => {
        if(!this.acidLevels)
            this.acidLevels = {}
        for (let i = 1; i <= this.cellsCount; i++) {
            this.acidLevels[i] = this.acidLevels[i] || null
        }
    }
    getMeasurements(){ return this.measurements}
    createMeasurements = () => {
        for (let i = 1; i <= this.cellsCount; i++) {
            this.measurements[i] = {
                cell: i,
                'cell_voltage': null
            }
        }
    }

    setRound(round) {
        this['measurement_round'] = round
    }

    addCellErrors = (cells = {}) => {
        const cellErrors = {}
        for (let i = 1; i <= this.cellsCount; i++) {
            const currentCell = cells[i - 1]
            cellErrors[i] = {
                'e_needs_cell_replacement': currentCell ? currentCell['e_needs_cell_replacement'] : false,
                'e_circulation': currentCell ? currentCell['e_circulation'] : false,
                'e_cell_plug': currentCell ? currentCell['e_cell_plug'] : false,
                'e_damaged_cell': currentCell ? currentCell['e_damaged_cell'] : false,
                'e_damaged_pole': currentCell ? currentCell['e_damaged_pole'] : false,
                notes: currentCell ? currentCell.notes : null,
                notesVisible: false
            }
        }
        this.cellErrors = cellErrors
    }

    setSumVoltage = (voltage) => {
        this['battery_sum_voltage'] = voltage
    }

}
